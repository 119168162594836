import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificadoInacalApiService } from 'src/app/services/api/certificado-inacal-api.service';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})

export class CertificadosComponent implements OnInit {
  certificadoForm: FormGroup;
  urlPDF: any = null;
  codigoHash: string = null;
  view: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private router: Router,
    private certificadoInacalApi: CertificadoInacalApiService,
    private route: ActivatedRoute,
    private _snackBar:MatSnackBar) { }

  async ngOnInit() {
    console.log("TDEM");
    this.route.paramMap.subscribe(params => {
      this.codigoHash = params.get('codigoHash');
      console.log("Codigo Hash", this.codigoHash);
      this.buildForm();
    });
  }
  
  private buildForm(){
    this.certificadoForm = this.formBuilder.group({
      CODIGO_SEGURIDAD: ["", Validators.required],
      NRO_CERTIFICADO: ["", Validators.required],
      NRO_SUMINSTRO: ["", Validators.required]
    });
  }

  async onSubmit() {
    if (!this.codigoHash) {
      this.verificarDatosVRF();
      return;
    }

    this.verificarDatosVRI();
  }

  private async verificarDatosVRI() {
    const { CODIGO_SEGURIDAD } = this.certificadoForm.value;
  
    if (!CODIGO_SEGURIDAD) {
      this.openSnackBar("Para continuar debe ingresar el código seguridad.", "");
      return;
    }
  
    await this.findCertificado(CODIGO_SEGURIDAD, this.codigoHash, null, null);
  }
  
  private async verificarDatosVRF() {
    const { NRO_CERTIFICADO, NRO_SUMINSTRO } = this.certificadoForm.value;

    if (!NRO_CERTIFICADO || !NRO_SUMINSTRO) {
      this.openSnackBar("Para continuar debe ingresar el número del certificado y suministro.", "");
      return;
    }

    await this.findCertificado(null, null, NRO_CERTIFICADO, NRO_SUMINSTRO);
  }

  private async findCertificado(codigoSeguridad: string, codigoHash: string, nroCertificado: string, nroSuministro: string) {
    try {
      console.log("1. sss", codigoSeguridad, codigoHash, nroCertificado, nroSuministro)
      const list: any = await this.certificadoInacalApi.getPromiseInicial(
        codigoSeguridad,
        codigoHash,
        nroCertificado,
        nroSuministro
      );

      console.log("2. sss", list)
      if (list.length > 0) {
        if(list[0].TIPO_SERVICIO =="VRI"){
          this.urlPDF = await this.sanitizarURL(list[0].URL);
          //this.urlPDF = await this.showPdf(list[0].URL);
          this.view = false;
        }else{
          const file = await this.certificadoInacalApi.getFile(list[0].CORREO, list[0].RUTA_ONE_DRIVE);
          console.log("fILE", file)
          const blobPDF = new Blob([file], { type: 'application/pdf' });
          
          // Crear la URL del objeto del Blob
          this.urlPDF = await this.sanitizarURL(URL.createObjectURL(blobPDF));
          this.view = false;
        }
      } else {
        this.openSnackBar(list.mensaje || "Error desconocido.", "");
        this.view = true;
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error);
      this.openSnackBar("Ocurrió un error al procesar la solicitud.", "");
    }
  }
  async showPdf(fileUrl: string) {
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error(`Error al descargar el archivo: ${response.statusText}`);
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    } catch (error) {
        console.error('Error al procesar el archivo PDF:', error);
    }
}
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ["success-snackbar"],
      duration: 10000
    });
  }

  async sanitizarURL(url: string): Promise<SafeResourceUrl> {
    await this.simulateAsyncOperation();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  simulateAsyncOperation(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000); // Simula un retraso de 1 segundo
    });
  }

  exit(){
    this.router.navigate(['/certificados']).then(() => {
      // Luego recargar la página
      window.location.reload();
    });
  }

}
