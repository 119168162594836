import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import api_rest from 'src/app/api_rest';
import { CERTIFICADO_INACAL } from 'src/app/models/certificado-inacal';

@Injectable({
  providedIn: 'root'
})
export class CertificadoInacalApiService {

  API_URI = api_rest.server.API_URI;
  API_URI_LUCY = api_rest.server.API_URI_LUCY;

  constructor(private http: HttpClient) { }
  mostrarTodos(){

    return this.http.get(`${this.API_URI}mostrarTodos.php`);

  }
  agregar(usuario){
    return this.http.post(`${this.API_URI}agregar.php`, JSON.stringify(usuario));
  }
  eliminar(id_usuario:number){
    return this.http.get(`${this.API_URI}eliminar.php?id_usuario=${id_usuario}`);
  }
  seleccionar(id_usuario:number){
    return this.http.get(`${this.API_URI}seleccionar.php?id_usuario=${id_usuario}`);
  }
  update(usuario){
    return this.http.post(`${this.API_URI}update.php`, JSON.stringify(usuario));
  }
  getPromisePosterior(nro_certificado: number, codigo_hash: string, dni_codigo: string, fecha:string):Promise<CERTIFICADO_INACAL[]> {
    console.log("Consulta API", `${this.API_URI}seleccionar.php?nro_certificado=${nro_certificado}&codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}&fecha=${fecha}`);
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}seleccionar.php?codigo_hash=${codigo_hash}&dni_codigo=${dni_codigo}`).toPromise(); 
  }
  getPromiseInicial(codigoSeguridad: string, codigoHash: string, nroCertificado: string, nroSuministro: string):Promise<CERTIFICADO_INACAL[]> {
    console.log("1. Consulta API", `${this.API_URI}list-verificacion-inicial.php`);
    console.log("2. Consulta API", `codigoHash=${codigoHash}`);
    console.log("3. Consulta API", `codigoSeguridad=${codigoSeguridad}`);
    console.log("4. Consulta API", `nroCertificado=${nroCertificado}`);
    console.log("5. Consulta API", `nroSuministro=${nroSuministro}`);
    console.log("6. Consulta API", `${this.API_URI}list-verificacion-inicial.php?codigoHash=${codigoHash}&codigoSeguridad=${codigoSeguridad}&nroCertificado=${nroCertificado}&nroSuministro=${nroSuministro}`);
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}list-verificacion-inicial.php?codigoHash=${codigoHash}&codigoSeguridad=${codigoSeguridad}&nroCertificado=${nroCertificado}&nroSuministro=${nroSuministro}`).toPromise(); 
  }

  getAllPromise():Promise<CERTIFICADO_INACAL[]> {
    return this.http.get<CERTIFICADO_INACAL[]>(`${this.API_URI}mostrarTodos.php`).toPromise();
  }

  getFile(email: string, filePath:string): Promise<Blob> {
    const params = new HttpParams()
      .set('email', email)
      .set('path', filePath);
    console.log("getFile - Parametro", params);
    return this.http.get(this.API_URI_LUCY, {
      params,
      responseType: 'blob' // Se espera un archivo como respuesta
    }).toPromise(); // Convierte el Observable en una Promesa
  }

}
